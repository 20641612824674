function Services() {
    return (
        <div>

            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                        <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
                        <h1 className="mb-0 oh-text-primary">Software development and digital media
                            solutions</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-desktop text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Web Development</h4>
                                <p className="m-0">Elevating Your Online Presence: Crafting Dynamic and Responsive Websites Tailored
                                    for Your Success.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-desktop text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-mobile-alt text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Mobile App Development</h4>
                                <p className="m-0">Empowering Your Business on the Go: Building Customized Mobile Apps for Seamless
                                    Engagement and Growth.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-mobile-alt text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-code text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Systems Development & Integrations</h4>
                                <p className="m-0">Seamless Systems for Enhanced Efficiency: Designing and Integrating Solutions
                                    that Streamline Operations and Drive Enterprise Excellence.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-code text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-chart-pie text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Data Analytics</h4>
                                <p className="m-0">Unlocking Insights, Driving Decisions: Harnessing the Power of Data Analytics to
                                    Illuminate Pathways for Informed Enterprise Strategies.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-chart-pie text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-video text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Digital Media Solutions</h4>
                                <p className="m-0">Crafting Impactful Digital Experiences: Transforming Ideas into Captivating
                                    Realities with Innovative Digital Media Solutions.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-video text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-chart-bar text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Social Media Management & Marketing</h4>
                                <p className="m-0">Amplifying Your Brand's Voice: Navigating the Digital Landscape with Strategic
                                    Social Media Management and Marketing Solutions.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-chart-bar text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                            <div
                                className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                                <h3 className="text-white mb-3">Get In Touch</h3>
                                <p className="text-white mb-3">Solutions Tailored to Your Needs. Reach out today for a personalized
                                    solution that aligns with your goals.</p>
                                <h2 className="text-white mb-0">+267 74 203 351</h2>
                                <h2 className="text-white mb-0">+267 74 179 693</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;