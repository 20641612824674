import React from 'react';
import { NavLink } from 'react-router-dom';

function Navbar() {
    return (

        <div>

            <div className="container-fluid bg-dark px-5 d-none d-lg-block">
                <div className="row gx-0">
                    <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center" style={{ height: '45px' }}>
                            <small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2"></i>Plot 5005, Thito, Maun,
                                Botswana</small>
                            <small className="me-3 text-light"><i className="fa fa-phone-alt me-2"></i>+267 74 203 351 / +267 74 179
                                693</small>
                            <small className="text-light"><i className="fa fa-envelope-open me-2"></i>info@openhive.co.bw</small>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center text-lg-end">
                        <div className="d-inline-flex align-items-center" style={{ height: '45px' }}>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank"
                                href="https://twitter.com/OpenHive_BW"><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank"
                                href="https://facebook.com/OpenHiveInc"><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank"
                                href="https://linkedin.com/company/open-hive-bw"><i
                                    className="fab fa-linkedin-in fw-normal"></i></a>

                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" target="_blank"
                                href="https://youtube.com/@OpenHive-Innovate.Create-qt7vx"><i
                                    className="fab fa-youtube fw-normal"></i></a>
                        </div>
                    </div>
                </div>
            </div>




            <div className="container-fluid position-relative p-0">
                <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
                    <a href="/" className="navbar-brand p-0">
                        <h1 className="m-0">
                            <img src="img/logo.png" alt="Startup Logo" className="me-2" />
                        </h1>
                    </a>

                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="fa fa-bars"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                            <NavLink exact="true" to="/" className="nav-item nav-link" activeclassname="active">
                                Home
                            </NavLink>
                            <NavLink to="/services" className="nav-item nav-link" activeclassname="active">
                                Services
                            </NavLink>
                            <NavLink to="/about" className="nav-item nav-link" activeclassname="active">
                                About
                            </NavLink>
                            <NavLink to="/contact" className="nav-item nav-link" activeclassname="active">
                                Contact
                            </NavLink>
                        </div>

                        <NavLink to="/contact" className="btn oh-btn-primary py-2 px-4 ms-3">
                            REQUEST A QUOTE
                        </NavLink>
                    </div>
                </nav>
            </div>
        </div>

    );
}

export default Navbar;
