function About() {
    return (
        <div>
            <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                        <h5 className="fw-bold text-primary text-uppercase">About Us</h5>
                        <h1 className="mb-0 oh-text-primary">Digital transformation and creative excellence</h1>
                    </div>
                    <div className="text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                        <p className="mb-4">Welcome to the forefront of innovation! We're a
                            trailblazing company dedicated
                            to
                            crafting exceptional software solutions and igniting digital media creativity. With a passion
                            for pushing boundaries, we seamlessly blend technology and imagination to bring your ideas to
                            life.</p>
                    </div>

                    <section className="timeline-section">
                        <ul>

                            <li>
                                <div>
                                    <time>Mission</time>
                                    Our mission is to empower businesses in Southern Africa through innovative software
                                    solutions that drive growth, efficiency, and profitability. We aim to leverage
                                    cutting-edge technologies and local talent to deliver high-quality software that meets
                                    the unique needs and challenges of businesses in the region. By providing world-class
                                    software development services, we strive to contribute to the economic development of
                                    Southern Africa and improve the lives of the people in the communities we serve. Our
                                    commitment to excellence, integrity, and customer satisfaction guides everything we do.

                                </div>
                            </li>

                            <li>
                                <div>
                                    <time>Vision</time>
                                    Our vision is to be the leading software development company in Southern Africa, recognized
                                    for our innovative solutions, exceptional customer service, and commitment to local economic
                                    development. We strive to become the go-to partner for businesses seeking reliable,
                                    high-quality software development services in the region. We aim to attract and retain the
                                    best local talent, and to create a culture that fosters creativity, collaboration, and
                                    growth. By leveraging emerging technologies and industry best practices, we will
                                    continuously improve the value we offer to our customers, and contribute to the growth and
                                    prosperity of Southern Africa.
                                </div>
                            </li>

                            <li>
                                <div>
                                    <time>Values</time>
                                    <strong>Customer-focused:</strong> We put our clients first and strive to understand their
                                    unique needs and
                                    requirements to deliver tailored software solutions that meet their business objectives.
                                    <br /><strong>Innovation-driven:</strong> We embrace innovation and continuously explore new
                                    technologies, tools,
                                    and frameworks to provide cutting-edge software solutions.
                                    <br /><strong>Excellence-oriented:</strong> We are committed to delivering exceptional results
                                    to
                                    our clients and
                                    strive for excellence in everything we do.
                                    <br /><strong>Collaborative:</strong> We work closely with our clients and value collaboration
                                    and
                                    open
                                    communication to ensure that we deliver high-quality software solutions that meet their
                                    needs.
                                </div>
                            </li>

                        </ul>
                    </section>

                </div>

            </div>

        </div>
    );
}

export default About;