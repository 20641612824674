function Footer() {
    return (
        <div>
            <div className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <div
                                className="d-flex flex-column align-items-center justify-content-center text-center h-100 oh-bg-white p-4">

                                <a href="/" className="navbar-brand p-0">
                                    <h1 className="m-0">
                                        <img src="img/logo.png" alt="Startup Logo" className="me-2" />
                                    </h1>
                                </a>
                                <p className="mt-3 mb-4 oh-text-primary">We are a pioneering company specializing in software
                                    development and digital media solutions.</p>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="row gx-5">
                                <div className="col-lg-4 col-md-12 pt-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Get In Touch</h3>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-geo-alt text-primary me-2"></i>
                                        <p className="mb-0">Plot 5005, Thito, Maun, Botswana</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-envelope-open text-primary me-2"></i>
                                        <p className="mb-0">info@openhive.co.bw</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-telephone text-primary me-2"></i>
                                        <p className="mb-0">+267 74 203 351</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-telephone text-primary me-2"></i>
                                        <p className="mb-0">+267 74 179 693 </p>
                                    </div>
                                    <div className="d-flex mt-4">
                                        <a className="btn oh-btn-primary btn-square me-2" href="#"><i
                                            className="fab fa-twitter fw-normal"></i></a>
                                        <a className="btn oh-btn-primary btn-square me-2" href="#"><i
                                            className="fab fa-facebook-f fw-normal"></i></a>
                                        <a className="btn oh-btn-primary btn-square me-2" href="#"><i
                                            className="fab fa-linkedin-in fw-normal"></i></a>
                                        <a className="btn oh-btn-primary btn-square" href="#"><i
                                            className="fab fa-instagram fw-normal"></i></a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Quick Links</h3>
                                    </div>
                                    <div className="link-animated d-flex flex-column justify-content-start">
                                        <a className="text-light mb-2" href="#"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Home</a>
                                        <a className="text-light mb-2" href="#"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                                        <a className="text-light mb-2" href="#"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Our Services</a>
                                        <a className="text-light" href="#"><i
                                            className="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid text-white" style={{ background: '#061429' }}>
                <div className="container text-center">
                    <div className="row justify-content-end">
                        <div className="col-lg-8 col-md-6">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: '75px' }}>
                                <p className="mb-0">&copy; <a className="text-white border-bottom" href="https://openhive.co.bw">Open
                                    Hive - Innovate. Create</a>. All
                                    Rights Reserved.

                                    Designed by <a className="text-white border-bottom" href="https://openhive.co.bw">Open Hive</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;