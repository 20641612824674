import React from 'react';

function Home() {
    return (
        <div>
            <div className="container-fluid py-5 wow fadeInUp oh-mt-15" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mt-5 mb-5">

                                <h1 className="mb-0 oh-text-primary">Creating a better world through innovation & creativity</h1>
                            </div>
                            <p className="mb-4">Technology serves as a powerful instrument, enhancing the depth and interactivity of
                                enterprise operations to benefit both the businesses and customers.</p>
                            <div className="row g-0 mb-3">
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                    <h5 className="mb-3 oh-text-primary"><i className="fa fa-check text-primary me-3"></i>Innovative Solutions</h5>
                                    <h5 className="mb-3 oh-text-primary"><i className="fa fa-check text-primary me-3"></i>Results-Driven</h5>
                                </div>
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                    <h5 className="mb-3 oh-text-primary"><i className="fa fa-check text-primary me-3"></i>Customer-Centric Approach</h5>
                                    <h5 className="mb-3 oh-text-primary"><i className="fa fa-check text-primary me-3"></i>Unparalleled Customer Support
                                    </h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                                <div className="bg-primary d-flex align-items-center justify-content-center rounded"
                                    style={{ width: '60px', height: '60px' }}>
                                    <i className="fa fa-phone-alt text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="mb-2">Call to ask any question</h5>
                                    <h4 className="text-primary mb-0">+267 74 203 351</h4>
                                    <h4 className="text-primary mb-0">+267 74 179 693</h4>
                                </div>
                            </div>
                            <a href="/services" className="btn oh-btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">See
                                our
                                services</a>
                        </div>
                        <div className="col-lg-5" style={{ minHeight: '500px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s"
                                    src="img/map.png" style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                        <h5 className="fw-bold text-primary text-uppercase">Why Choose Us</h5>
                        <h1 className="mb-0 oh-text-primary">Our customers are at the heart of everything we do</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-4">
                            <div className="row g-5">
                                <div className="col-12 wow zoomIn" data-wow-delay="0.2s">
                                    <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-lightbulb text-white"></i>
                                    </div>
                                    <h4 className="oh-text-primary">Innovative Solutions</h4>
                                    <p className="mb-0">Our commitment to innovation ensures we provide cutting-edge solutions that
                                        keep you ahead in the digital landscape.</p>
                                </div>
                                <div className="col-12 wow zoomIn" data-wow-delay="0.6s">
                                    <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-award text-white"></i>
                                    </div>
                                    <h4 className="oh-text-primary">Results-Driven</h4>
                                    <p className="mb-0">We deliver tangible outcomes, working tirelessly to achieve measurable
                                        results that contribute to your growth.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className="row g-5">
                                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                                    <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-users text-white"></i>
                                    </div>
                                    <h4 className="oh-text-primary">Customer-Centric Approach</h4>
                                    <p className="mb-0">With a focus on you, our approach tailors services to your unique needs,
                                        creating a truly collaborative partnership.</p>
                                </div>
                                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                                    <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-bullseye text-white"></i>
                                    </div>
                                    <h4 className='oh-text-primary'>Precision and Performance</h4>
                                    <p className="mb-0">We blend the meticulous precision of our processes with the pursuit of peak
                                        performance, to present nothing less than
                                        exceptional outcomes.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="row g-5">
                                <div className="col-12 wow zoomIn" data-wow-delay="0.4s">
                                    <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-binoculars text-white"></i>
                                    </div>
                                    <h4 className='oh-text-primary'>Strategic Vision</h4>
                                    <p className="mb-0">Guided by a forward-thinking perspective, we devise strategic solutions that
                                        anticipate trends and position you for lasting success.</p>
                                </div>
                                <div className="col-12 wow zoomIn" data-wow-delay="0.8s">
                                    <div className="bg-primary rounded d-flex align-items-center justify-content-center mb-3"
                                        style={{ width: '60px', height: '60px' }}>
                                        <i className="fa fa-phone-alt text-white"></i>
                                    </div>
                                    <h4 className="oh-text-primary">Unparalleled Customer Support</h4>
                                    <p className="mb-0">Beyond completion, our dedicated customer support guarantees assistance and
                                        guidance every step of the way.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600p' }}>
                        <h5 className="fw-bold text-primary text-uppercase">Our Services</h5>
                        <h1 className="mb-0 oh-text-primary">Software development and digital media
                            solutions</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-desktop text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Web Development</h4>
                                <p className="m-0">Elevating Your Online Presence: Crafting Dynamic and Responsive Websites Tailored
                                    for Your Success.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-desktop text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-mobile-alt text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Mobile App Development</h4>
                                <p className="m-0">Empowering Your Business on the Go: Building Customized Mobile Apps for Seamless
                                    Engagement and Growth.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-mobile-alt text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-code text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Systems Development & Integrations</h4>
                                <p className="m-0">Seamless Systems for Enhanced Efficiency: Designing and Integrating Solutions
                                    that Streamline Operations and Drive Enterprise Excellence.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-code text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-chart-pie text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Data Analytics</h4>
                                <p className="m-0">Unlocking Insights, Driving Decisions: Harnessing the Power of Data Analytics to
                                    Illuminate Pathways for Informed Enterprise Strategies.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-chart-pie text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-video text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Digital Media Solutions</h4>
                                <p className="m-0">Crafting Impactful Digital Experiences: Transforming Ideas into Captivating
                                    Realities with Innovative Digital Media Solutions.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-video text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div
                                className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-chart-bar text-white"></i>
                                </div>
                                <h4 className="mb-3 oh-text-primary">Social Media Management & Marketing</h4>
                                <p className="m-0">Amplifying Your Brand's Voice: Navigating the Digital Landscape with Strategic
                                    Social Media Management and Marketing Solutions.</p>
                                <a className="btn btn-lg oh-btn-primary rounded" href="">
                                    <i className="fa fa-chart-bar text-white"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                            <div
                                className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-5">
                                <h3 className="text-white mb-3">Get In Touch</h3>
                                <p className="text-white mb-3">Solutions Tailored to Your Needs. Reach out today for a personalized
                                    solution that aligns with your goals.</p>
                                <h2 className="text-white mb-0">+267 74 203 351</h2>
                                <h2 className="text-white mb-0">+267 74 179 693</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h5 className="fw-bold text-primary text-uppercase">Connect With Us</h5>
                                <h1 className="mb-0 oh-text-primary">Got a question? Please Feel Free to Contact Us</h1>
                            </div>
                            <div className="row gx-3">
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                    <h5 className="mb-4 oh-text-primary"><i className="fa fa-reply text-primary me-3"></i>Reply within 24 hours</h5>
                                </div>
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                    <h5 className="mb-4 oh-text-primary"><i className="fa fa-phone-alt text-primary me-3"></i>24 hrs telephone support
                                    </h5>
                                </div>
                            </div>
                            <p className="mb-4">Connect with us to discuss your requirements and receive a comprehensive, customized
                                quote. Our team is ready to delve into your unique needs, crafting a solution that not only
                                meets but exceeds your expectations. Reach out now for a detailed consultation and a quote that
                                lays the foundation for your next big venture.</p>
                            <div className="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
                                <div className="bg-primary d-flex align-items-center justify-content-center rounded"
                                    style={{ width: '60px', height: '60px' }}>
                                    <i className="fa fa-phone-alt text-white"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="mb-2 oh-text-primary">Call to ask any question</h5>
                                    <h4 className="text-primary mb-0">+267 74 203 351</h4>
                                    <h4 className="text-primary mb-0">+267 74 179 693</h4>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn"
                                data-wow-delay="0.9s">
                                <form>
                                    <div className="row g-3">
                                        <div className="col-xl-12">
                                            <input type="text" className="form-control bg-light border-0" placeholder="Your Name"
                                                style={{ height: '55px' }} />
                                        </div>
                                        <div className="col-12">
                                            <input type="email" className="form-control bg-light border-0" placeholder="Your Email"
                                                style={{ height: '55px' }} />
                                        </div>
                                        <div className="col-xl-12">
                                            <input type="text" className="form-control bg-light border-0" placeholder="Subject"
                                                style={{ height: '55px' }} />
                                        </div>
                                        <div className="col-12">
                                            <textarea className="form-control bg-light border-0" rows="3"
                                                placeholder="Message"></textarea>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-dark w-100 py-3" type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Home;
